import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import {AuthGuard} from './shared/services/auth/auth.guard';


export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'sessoes',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessoes',
        loadChildren: () => import('./views/sessoes/sessoes.module').then(m => m.SessoesModule),
        data: { title: 'Login'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD'}
      },

      {
        path: 'administracao',
        loadChildren: () =>
            import('./views/administracao/administracao.module').then(
                (m) => m.AdministracaoModule
            ),
        data: { title: 'Administração', breadcrumb: 'Administração' },
      },
      {
        path: 'clientes',
        loadChildren: () =>
            import('./views/clientes/clientes.module').then(
                (m) => m.ClientesModule
            ),
        data: { preload: true },
      },
      {
        path: 'artigos',
        loadChildren: () =>
            import('./views/artigos/artigos.module').then((m) => m.ArtigosModule),
        data: { preload: true },
      },
      {
        path: 'fornecedores',
        loadChildren: () =>
            import('./views/fornecedores/fornecedores.module').then(
                (m) => m.FornecedoresModule
            ),
        data: { preload: true },
      },
      {
        path: 'processos',
        loadChildren: () =>
            import('./views/processos/processos.module').then(
                (m) => m.ProcessosModule
            ),
        data: { title: 'Processos', breadcrumb: 'Processos' },
      },
      {
        path: 'eventos',
        loadChildren: () =>
            import('./views/eventos/eventos.module').then((m) => m.EventosModule),
        data: { preload: false },
      },
      {
        path: 'rh',
        loadChildren: () =>
            import('./views/rh/rh.module').then((m) => m.RhModule),
        data: { preload: false },
      },
      {
        path: 'facturacao',
        loadChildren: () =>
            import('./views/facturacao/facturacao.module').then((m) => m.FacturacaoModule),
        data: { preload: false },
      },
      {
        path: 'tesouraria',
        loadChildren: () =>
            import('./views/tesouraria/tesouraria.module').then(
                (m) => m.TesourariaModule
            ),
        data: { preload: false },
      },
      {
        path: 'equipamentos',
        loadChildren: () =>
            import('./views/equipamentos/equipamentos.module').then(
                (m) => m.EquipamentosModule
            ),
        data: { preload: false },
      },
      {
        path: 'comunicacao',
        loadChildren: () =>
            import('./views/comunicacao/comunicacao.module').then(
                (m) => m.ComunicacaoModule
            ),
        data: { preload: false },
      },
      {
        path: 'responsaveis',
        loadChildren: () =>
            import('./views/responsaveis/responsaveis.module').then(
                (m) => m.ResponsaveisModule
            ),
        data: { title: 'Equipas ', breadcrumb: 'Equipas' },
      },
      {
        path: 'utilizadores',
        loadChildren: () =>
            import('./views/utilizadores/utilizadores.module').then(
                (m) => m.UtilizadoresModule
            ),
        data: { title: 'Utilizadores', breadcrumb: 'Utilizadores' },
      },
      {
        path: 'manutencao',
        loadChildren: () =>
            import('./views/manutencao/manutencao.module').then(
                (m) => m.ManutencaoModule
            ),
        data: { preload: false },
      },
      {
        path: 'administracao',
        loadChildren: () =>
            import('./views/administracao/administracao.module').then(
                (m) => m.AdministracaoModule
            ),
        data: { title: 'Administração', breadcrumb: 'Administração' },
      },

      {
        path: 'empresas',
        loadChildren: () =>
            import('./views/administracao/administracao.module').then(
                (m) => m.AdministracaoModule
            ),
        data: { title: 'Empresas', breadcrumb: 'Empresas' },
      },
      {
        path: 'perfil',
        loadChildren: () =>
            import('./views/perfil/perfil.module').then((m) => m.PerfilModule),
        data: { title: 'Perfil', breadcrumb: 'Perfil' },
      },
      {
        path: 'relatorios',
        loadChildren: () =>
            import('./views/relatorios/relatorios.module').then((m) => m.RelatoriosModule),
        data: { title: 'Relatorios', breadcrumb: 'Relatorios' },
      },
      {
        path: 'cms',
        loadChildren: () =>
            import('./views/cms/cms.module').then((m) => m.CMSModule),
        data: { title: 'CMS', breadcrumb: 'CMS' },
      }

    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

