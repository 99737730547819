import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Entidades } from 'app/shared/models/clientes/entidades';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Titulos } from 'app/shared/models/aplicacao/titulos';
import { TiposEnderecoCliente } from 'app/shared/models/clientes/tiposenderecoCliente';
import { environment } from 'environments/environment';
import { Tipos } from 'app/shared/models/clientes/tipos';
import { TiposArquivos } from 'app/shared/models/clientes/tiposarquivos';
import { TiposDocumentosCliente } from 'app/shared/models/clientes/tiposdocumentos';
import { ClienteAcesso } from 'app/shared/models/clientes/clienteacessos';
import { Codigo } from 'app/shared/models/clientes/codigo';
import { ConfCodigosClientes } from 'app/shared/models/clientes/confcodigos';
import { EntidadesRelacoes } from 'app/shared/models/clientes/entidadesrelacoes';
import { Estados } from 'app/shared/models/clientes/estados';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ClientesGeraisService {
  baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiAplicacao}`;
  }

  getEntidades(): Observable<any> {
    return this.http
      .get<Entidades[]>(this.baseUrl + 'entidades', httpOptions);
      // .get(this.baseUrl + "clientesEntidades", httpOptions)
  }

  getEntidade(idEntidade: number): Observable<any> {
    return this.http.get(this.baseUrl + 'entidades/' + idEntidade, httpOptions);
  }

  getEntidadesRelacoesFilhos(
    idEntidadeFilho: number
  ): Observable<any> {
    return this.http
      .get(
        this.baseUrl +
          'entidades/relacoesEntidadesFilhos/' +
          idEntidadeFilho, httpOptions
      )
      .pipe(catchError(null));
  }

  getTitulos(): Observable<Titulos[]> {
    return this.http
      .get(this.baseUrl + 'aplicacao/titulos', httpOptions)
      .pipe(catchError(null));
  }

  getAcessos(id: number): Observable<ClienteAcesso[]> {
    return this.http
      .get(this.baseUrl + 'clientes/acessos/' + id, httpOptions)
      .pipe(catchError(null));
  }

  getAcessosCliente(id: number): Observable<ClienteAcesso[]> {
    return this.http
      .get(this.baseUrl + 'clientes/acessosclientes/' + id, httpOptions)
      .pipe(catchError(null));
  }

  getAcesso(id: number, idCliente: number): Observable<ClienteAcesso> {
    return this.http
      .get(
        this.baseUrl + 'clientes/acessos/' + id + '/' + idCliente,
        httpOptions
      )
      .pipe(catchError(null));
  }

  getEstados(idEntidade: number): Observable<Estados[]> {
    return this.http
      .get(this.baseUrl + 'clientes/estados/' + idEntidade, httpOptions)
      .pipe(catchError(null));
  }

  getTodosEstados(): Observable<Estados[]> {
    return this.http
      .get(this.baseUrl + 'clientes/estados', httpOptions)
      .pipe(catchError(null));
  }
  getEstado(id: number): Observable<any> {
    return this.http
      .get(this.baseUrl + 'clientes/estados/estadoprincipal/' + id, httpOptions)
      .pipe(catchError(null));
  }
  getEstadoDetalhe(idEstado: number): Observable<Estados> {
    return this.http
      .get(this.baseUrl + 'clientes/estados/estado/' + idEstado, httpOptions)
      .pipe(catchError(null));
  }
  getDetalheEstado(idEstado: number, idEntidade: number): Observable<Estados> {
    return this.http
      .get(
        this.baseUrl + 'clientes/estados/' + idEstado + '/' + idEntidade,
        httpOptions
      )
      .pipe(catchError(null));
  }
  ActivarEstado(modelo: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'clientes/estados/activarEstado/', modelo, httpOptions)
      .pipe();
  }

  TornarEstadoDefault(modelo: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'clientes/estados/estadoprincipal/', modelo, httpOptions)
      .pipe();
  }
  gravarEstado(modelo: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'clientes/estados/', modelo, httpOptions)
      .pipe();
  }
  EliminarEstado(idEstado: any): Observable<any> {
    return this.http
      .delete(this.baseUrl + 'clientes/estados/eliminar/' + idEstado, httpOptions)
      .pipe();
  }

  getTipos(idEntidade: number): Observable<Tipos[]> {
    return this.http
      .get(this.baseUrl + 'clientesTipos/entidade/' + idEntidade, httpOptions)
      .pipe(catchError(null));
  }

  getTipoCliente(idTipo: number): Observable<any> {
    return this.http
      .get(this.baseUrl + 'clientesTipos/' + idTipo, httpOptions)
      .pipe(catchError(null));
  }

  // Tipo de Documentos

  gravarTipoDocumento(modelo: any) {
    return this.http
      .post(this.baseUrl + 'clientes/tipos-documentos/', modelo, httpOptions)
      .pipe(catchError(this.handleError));
  }

  editarTipoDocumento(modelo: any) {
    return this.http
      .post(
        this.baseUrl + 'clientes/tipos-documentos/editar',
        modelo,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  removerTipoDocumento(modelo: any) {
    return this.http
      .post(
        this.baseUrl + 'clientes/tipos-documentos/remover/',
        modelo,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  activarTipoDocumento(modelo: any) {
    return this.http
      .post(
        this.baseUrl + 'clientes/tipos-documentos/activar/',
        modelo,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getTiposDocumentos(idEntidade: number): Observable<TiposDocumentosCliente[]> {
    return this.http
      .get(
        this.baseUrl + 'clientes/tipos-documentos/entidade/' + idEntidade,
        httpOptions
      )
      .pipe(catchError(null));
  }

  getTodosTiposDocumentos(): Observable<TiposDocumentosCliente[]> {
    return this.http
      .get(this.baseUrl + 'clientes/tipos-documentos/', httpOptions)
      .pipe(catchError(null));
  }

  getTipoDocumento(
    idTipo: number,
    idEntidade: number
  ): Observable<TiposDocumentosCliente> {
    return this.http
      .get(
        this.baseUrl + 'clientes/tipos-documentos/' + idTipo + '/' + idEntidade,
        httpOptions
      )
      .pipe(catchError(null));
  }

  getTiposDocumentosEntidade(
    idEntidade: Number
  ): Observable<TiposDocumentosCliente[]> {
    return this.http
      .get(
        this.baseUrl + 'clientes/tipos-documentos/entidade/' + idEntidade,
        httpOptions
      )
      .pipe(catchError(null));
  }


  getTiposArquivos(idEntidade: number): Observable<any> {
    return this.http.get(this.baseUrl + 'clientesTiposArquivos/entidade/' + idEntidade, httpOptions)
      .pipe();
  }

  getTipoArquivo(id: number): Observable<any> {
    return this.http
      .get(this.baseUrl + 'clientesTiposArquivos/' + id, httpOptions)
      .pipe(catchError(null));
  }

  getTiposEnderecos(idEntidade: number): Observable<TiposEnderecoCliente[]> {
    return this.http
      .get(this.baseUrl + 'clientes/tiposenderecos/' + idEntidade, httpOptions)
      .pipe(catchError(null));
  }

  getTipoEndereco(id: number): Observable<TiposEnderecoCliente> {
    return this.http
      .get(this.baseUrl + 'clientes/tiposenderecos/' + id, httpOptions)
      .pipe(catchError(null));
  }

  getCodigo(idEntidade: number, idTipo: number): Observable<any> {
    return this.http
      .get(this.baseUrl + 'clientes/codigos/' + idEntidade + '/' + idTipo, httpOptions);
  }

  getCodigoEntidade(idEntidade?: number, idTipo?: number, dataRegisto?: any): Observable<any> {
   // return this.http.get(this.baseUrl + 'clientes/proximoCodigo/' + idEntidade + '/' + idTipo + '/' + dataRegisto);
    return this.http.post(this.baseUrl + 'clientes/proximoCodigo', {idEntidade, idTipo, dataRegisto});
  }



  getConfCodigo(
    idEntidade: number,
    idTipo: number
  ): Observable<ConfCodigosClientes> {
    return this.http
      .get(
        this.baseUrl +
          'clientes/codigotipoinsercao/' +
          idEntidade +
          '/' +
          idTipo,
        httpOptions
      )
      .pipe(catchError(null));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  /*Post*/
  salvarEstado(model: any) {
    return this.http
      .post(this.baseUrl + 'clientes/estados/', model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  removerEstado(modelo: any) {
    return this.http
      .post(this.baseUrl + 'clientes/estados/remover/', modelo, httpOptions)
      .pipe(catchError(this.handleError));
  }

  salvarTipos(model: any) : Observable<any> {
    return this.http
      .post(this.baseUrl + 'clientesTipos/', model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  removerTipos(model: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'clientesTipos/remover', model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  salvarTiposArquivos(model: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'clientesTiposArquivos', model, httpOptions)
      .pipe();
  }

  removerTiposArquivos(model: any): Observable<any> {
    return this.http
      .post(
        this.baseUrl + 'clientes/tiposarquivos/remover',
        model,
        httpOptions
      )
      .pipe();
  }

  salvarAcessos(model: any) {
    return this.http
      .post(this.baseUrl + 'clientes/acessos/', model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  apagarAcesso(model: any) {
    return this.http
      .post(this.baseUrl + 'clientes/acessos/remover/', model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  editarAcesso(model: any) {
    return this.http
      .post(this.baseUrl + 'clientes/acessos/editar/', model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  editarPasswordAcesso(model: any) {
    return this.http
      .post(
        this.baseUrl + 'clientes/acessos/editarpassword/',
        model,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }
  
  // Caracteristicas
  getCaracteristicas(idClassificacao: number, activo: boolean): Observable<any> {
    return this.http
      .get(this.baseUrl + 'clientes/caracteristicas/' + idClassificacao + '/' + activo, httpOptions)
      .pipe(catchError(null));
  }

  getTiposDadosCaracteristica(): Observable<any> {
    return this.http.get(this.baseUrl + 'clientes/caracteristicas/tiposDados',  httpOptions);
  }

  // parametrizacao
  getParametrizacao(): Observable<any> {
    return this.http
    //  .get(this.baseUrl + 'clientes/parametrizacao/', httpOptions)
      .get(this.baseUrl + 'entidades/', httpOptions)
      .pipe(catchError(null));
  }

  eliminarEntidade(idEntidade:number): Observable<any> {
    return this.http
    //  .get(this.baseUrl + 'clientes/parametrizacao/', httpOptions)
      .delete(this.baseUrl + 'entidades/' + idEntidade, httpOptions)
      .pipe();
  }

  getParametrizacaoEntidade(idEntidade:number): Observable<any> {
    return this.http
   //   .get(this.baseUrl + 'clientes/parametrizacao/' + idEntidade, httpOptions)
    .get(this.baseUrl + 'entidades/' + idEntidade, httpOptions)
      .pipe(catchError(null));
  }

  salvarParametrizacao(modelo:any): Observable<any> {
    return this.http
//EJVC      .post(this.baseUrl + 'clientes/parametrizacao',modelo, httpOptions)
      .post(this.baseUrl + 'entidades',modelo, httpOptions)
      .pipe();
  }
}
