import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Utilizadores} from '../../models/aplicacao/utilizadores';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  private userData$ = new BehaviorSubject<Utilizadores>(null);
  private moduloProcessosActivo$ = new BehaviorSubject<boolean>(null);
  private logotipoApp$ = new BehaviorSubject<any>(null);
  private nome$ = new BehaviorSubject<string>('');
  private fotoPerfil$ = new BehaviorSubject<string>('');
  private varianteTab$ = new BehaviorSubject<any>(null);
   exportar = new BehaviorSubject<boolean>(false);
    rotaActual = new BehaviorSubject<string>(null);

    constructor() { }

    setRotalActual(rota: string) {
        this.rotaActual.next(rota);
    }

    getRotalActual() {
        return  this.rotaActual.asObservable();
    }
  setEstadoModuloProcessos(estado: boolean) {
    this.moduloProcessosActivo$.next(estado);
  }

 getEstadoModuloProcessos(): Observable<any> {
   return this.moduloProcessosActivo$.asObservable();
  }

    setInfoUtilizador(utilizador: Utilizadores) {
        this.userData$.next(utilizador);
    }

    getInfoUtilizador(): Observable<Utilizadores> {
        return this.userData$.asObservable();
    }

  setNome(nome: string) {
    this.nome$.next(nome);
  }

    setFotoPerfil(foto: string) {
        this.fotoPerfil$.next(foto);
    }
    getFotoPerfil(): Observable<string> {
        return this.fotoPerfil$.asObservable();
    }

  getNome(): Observable<string> {
   return this.nome$.asObservable();
  }
  setTabVariante(tabvariante: any) {
    this.varianteTab$.next(tabvariante);
  }
  getTabVariante() {
    return this.varianteTab$.asObservable();
}

setExportar(exportar: boolean) {
      this.exportar.next(exportar);
}

getExportar() {
      return this.exportar.asObservable();
}
}
