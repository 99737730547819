import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelativeTimePipe } from './relative-time.pipe';
import { ExcerptPipe } from './excerpt.pipe';
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import {SafePipe} from './safe.pipe';
import {StringFloatPipe} from './string-float.pipe';
import {FormatarOrdinalPipe} from './formatar-ordinal.pipe';
import {TipoFicheiroPipe} from './tipo-ficheiro.pipe';
import {InserirQuebraLinhaPipe} from './inserir-quebra-linha.pipe';
import {PrintWithBrPipe} from './print-with-br.pipe';
import {LabelProvinciaPipe} from './label-provincia.pipe';
import {LabelMunicipioPipe} from './label-municipio.pipe';
import {StrNormalCasePipe} from './str-normal-case.pipe';
import {LinkYoutubePipe} from './link-youtube.pipe';
import {TamanhoFicheiroPipe} from './tamanhoFicheiro.pipe';
import { IconFicheiroPipe } from './icon-ficheiro.pipe';
import {SetBgFicheiroPipe} from './set-bg-ficheiro.pipe';


const pipes = [
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  StringFloatPipe,
  FormatarOrdinalPipe,
  TipoFicheiroPipe,
  InserirQuebraLinhaPipe,
  PrintWithBrPipe,
    LabelProvinciaPipe,
    LabelMunicipioPipe,
  StrNormalCasePipe,
  LinkYoutubePipe,
  TamanhoFicheiroPipe,
    IconFicheiroPipe,
  SetBgFicheiroPipe
];

@NgModule({
  imports: [
    CommonModule
  ],
    declarations: [
        pipes,
        SafePipe,

    ],
    exports: [
        pipes,
        SafePipe
    ]
})
export class SharedPipesModule {}
