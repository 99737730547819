import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { ProcessosClassificacoes } from 'app/shared/models/processos/processosclassificacoes';
import { catchError } from 'rxjs/operators';
import { ProcessosTipos } from 'app/shared/models/processos/processostipos';
import { ProcessosEstados } from 'app/shared/models/processos/processosestados';
import { Processos } from 'app/shared/models/processos/processos';
import { ProcessosTarefas } from 'app/shared/models/processos/processostarefas';
import { ProcessosClientes } from 'app/shared/models/processos/processosclientes';
import { ProcessosArquivosTipos } from 'app/shared/models/processos/processosarquivostipos';
import { Codigo } from 'app/shared/models/processos/codigo';
import { Responsavel } from 'app/shared/models/processos/responsavel';
import { TiposTarefas } from 'app/shared/models/processos/tipostarefas';
import { Caracteristicas } from 'app/shared/models/processos/caracteristicas';
import { Categorias } from 'app/shared/models/processos/categorias';
import { ArtigosFornecedor}  from 'app/shared/models/processos/artigosfornecedor';
import { ClassificacaoParametrizacao } from 'app/shared/models/processos/classificacaoparametrizacao';
import { ProcessosEmailsAutomaticos } from 'app/shared/models/processos/emails-automaticos';
import { EmailsEnvio } from 'app/shared/models/processos/emailsenvio';
import { TipoLocalizacao } from 'app/shared/models/processos/localizacao/tiposlocalizacao';
import { Localizacao } from 'app/shared/models/processos/localizacao/localizacao';

const httpOptionsPost = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),

  }),
};

@Injectable({
  providedIn: 'root',
})
export class ProcessosService {

  baseUrl: string;
  baseUrlProcesso: string;

  constructor(private http: HttpClient) {
   this.baseUrl = `${environment.apiAplicacao}`;
   this.baseUrlProcesso = `${environment.apiProcessos}`;
  }
  getEmailsAutomaticos(idClassificacao: number): Observable<ProcessosEmailsAutomaticos[]> {
    return this.http
      .get(
        this.baseUrlProcesso + 'emailsautomaticos/' + idClassificacao,
        httpOptionsPost
      )
      .pipe(catchError(null));
  }
  gravarEmailsAutomatico(modelo: any): Observable<any> {
    return this.http
      .post(
        this.baseUrlProcesso + 'emailsautomaticos' , modelo,
        httpOptionsPost
      )
      .pipe();
  }
  getEmails(
    activo: boolean,
    disponivelEmails: boolean
  ): Observable<EmailsEnvio[]> {
    return this.http
      .get(
        this.baseUrlProcesso +
          'emailsautomaticos/emailsEnvio/' +
          activo +
          '/' +
          disponivelEmails,
          httpOptionsPost
      )
      .pipe(catchError(null));
  }

  getPeriodicidadeEmails(): Observable<any[]>{
    return this.http
    .get(
      this.baseUrlProcesso + 'notificacao/getPeriodicidadeEmails',
      httpOptionsPost
    )
    .pipe(catchError(null));
  }
  getEmailAutomatico(idEmailAutomatico: number, idClassificacao: number): Observable<ProcessosEmailsAutomaticos> {
    return this.http
      .get(
        this.baseUrlProcesso + 'emailsautomaticos/' + idEmailAutomatico + '/' + idClassificacao,
        httpOptionsPost
      )
      .pipe(catchError(null));
  }

  getClassificacoes(): Observable<ProcessosClassificacoes[]> {
    return this.http
      .get(
        this.baseUrlProcesso + 'classificacoes',
        httpOptionsPost
      )
      .pipe(catchError(null));
  }
  getClassificacoesParametrizacao(): Observable<ClassificacaoParametrizacao[]> {
    return this.http
      .get(
        this.baseUrlProcesso + 'classificacoes/parametrizacao',
        httpOptionsPost
      )
      .pipe(catchError(null));
  }
  salvarParametrizacao(modelo: any): Observable<any> {
    return this.http.post(
        this.baseUrlProcesso + 'classificacoes', modelo, httpOptionsPost
      )
      .pipe();
  }
  getClassificacao(id: number): Observable<ProcessosClassificacoes> {
    return this.http
      .get(this.baseUrlProcesso + 'classificacoes/' + id, httpOptionsPost)
      .pipe(catchError(null));
  }

  getProcessos(model: any): Observable<Processos[]> {
    return this.http
      .post(
        this.baseUrlProcesso + 'processos/GetPrVProcessos',
        model,
        httpOptionsPost
      )
      .pipe(catchError(null));
  }


  getProcesso(idProcesso: number, idIdioma: number): Observable<any> {
    return this.http
      .get(
        this.baseUrlProcesso + 'processos/processo/' + idIdioma + '/' + idProcesso,
        httpOptionsPost
      )
      .pipe();
  }
  removerProcesso(idProcesso: number): Observable<any> {
    return this.http
      .post(
        this.baseUrlProcesso + 'processos/eliminar',
        idProcesso,
        httpOptionsPost
      )
      .pipe(catchError(null));
  }
  removerTipoProcesso(idTipoProcesso: number): Observable<any> {
    return this.http
      .post(
        this.baseUrlProcesso + 'tipos/eliminar',
        idTipoProcesso,
        httpOptionsPost
      )
      .pipe(catchError(null));
  }

  getClientes(): Observable<ProcessosClientes[]> {
    return this.http
      .get(this.baseUrlProcesso + 'clientes', httpOptionsPost)
      .pipe(catchError(null));
  }

  getTipos(idClassificacao: number): Observable<ProcessosTipos[]> {
    return this.http
      .get(this.baseUrlProcesso + 'tipos/' + idClassificacao, httpOptionsPost)
      .pipe(catchError(null));
  }
  getTipo(idTipo: number): Observable<ProcessosTipos> {
    return this.http
      .get(this.baseUrlProcesso + 'tipos/tipo/' + idTipo, httpOptionsPost)
      .pipe(catchError(null));
  }

  getTarefas(model: any): Observable<ProcessosTarefas[]> {
    return this.http
      .post(
        this.baseUrlProcesso + 'tarefas/GetPrtarefas',
        model,
        httpOptionsPost
      )
      .pipe(catchError(null));
  }

  getEstados(idClassificacao: number): Observable<ProcessosEstados[]> {
    return this.http
      .get(this.baseUrlProcesso + 'estados/' + idClassificacao, httpOptionsPost)
      .pipe(catchError(null));
  }
  getEstado(idEstado: number): Observable<ProcessosEstados> {
    return this.http
      .get(this.baseUrlProcesso + 'estados/PrEstados/' + idEstado, httpOptionsPost)
      .pipe(catchError(null));
  }

  salvar(model: any): Observable<any> {
    return this.http
      .post(this.baseUrlProcesso + 'processos/', model, httpOptionsPost)
      .pipe();
  }
  editar(model: any): Observable<any> {
    return this.http
      .post(this.baseUrlProcesso + 'processos/Atualizar', model, httpOptionsPost)
      .pipe();
  }

  gerarToken(model: any) {
    return this.http
      .post(this.baseUrlProcesso + 'gerartoken/', model)
      .pipe(catchError(null));
  }

  getCodigoProcesso(idClassificacao: number, ano: string, idTipoProcesso: number, idEmpresa: number): Observable<Codigo> {
    return this.http
      .get(
        this.baseUrlProcesso +
        'numeracao/' +
        idClassificacao +
        '/' +
        ano +
        '/' +
        idTipoProcesso +
        '/' +
        idEmpresa,
        httpOptionsPost
      )
      .pipe(catchError(null));
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
  getResponsavel(idClassificacao: number): Observable<any> {
    return this.http
      .get(this.baseUrlProcesso + 'responsavel/' + idClassificacao, httpOptionsPost)
      .pipe(catchError(null));
  }
  getResponsavelTipo(idClassificacao: number): Observable<any> {
    return this.http
      .get(this.baseUrlProcesso + 'responsavel/tipo/' + idClassificacao, httpOptionsPost)
      .pipe(catchError(null));
  }
  // Caracteristicas
  getCaracteristicas(idClassificacao: number, activo: boolean): Observable<any> {
    return this.http
      .get(this.baseUrlProcesso + 'caracteristicas/' + idClassificacao + '/' + activo, httpOptionsPost)
      .pipe(catchError(null));
  }
  // Categoria
  getCategorias(idClassificacao: number, idIdioma: number): Observable<Categorias[]> {
    return this.http
      .get(this.baseUrlProcesso + 'categorias/' + idClassificacao + '/' + idIdioma, httpOptionsPost)
      .pipe(catchError(null));
  }
  getCategoriasConfiguracao(idClassificacao: number, idIdioma: number): Observable<Categorias[]> {
    return this.http
      .get(this.baseUrlProcesso + 'categorias/categorias/' + idClassificacao + '/' + idIdioma, httpOptionsPost)
      .pipe(catchError(null));
  }
  getCategoria(idCategoria: number): Observable<Categorias> {
    return this.http
      .get(this.baseUrlProcesso + 'categorias/categoria/' + idCategoria, httpOptionsPost)
      .pipe(catchError(null));
  }
  SalvarCategoria(model: any): Observable<Categorias> {
    return this.http
      .post(this.baseUrlProcesso + 'categorias/', model, httpOptionsPost)
      .pipe(catchError(null));
  }
  eliminarCategoria(idCategoria: number): Observable<Categorias> {
    return this.http
      .post(this.baseUrlProcesso + 'categorias/eliminar', idCategoria, httpOptionsPost)
      .pipe(catchError(null));
  }
  // Salvar Estado
  salvarEstado(model: any): Observable<boolean> {
    return this.http
      .post(this.baseUrlProcesso + 'estados/', model, httpOptionsPost)
      .pipe(catchError(null));
  }

  // Eliminar Estado fora de funcionalidade
  eliminarEstadoConfig(idEstado: number) {
    return this.http
      .post(this.baseUrlProcesso + 'estados/eliminar/', idEstado, httpOptionsPost)
      .pipe(catchError(null));
  }
  eliminarEstado(id: number) {
    return this.http
      .post(this.baseUrlProcesso + 'tipostarefas/eliminar/estado/' , id, httpOptionsPost)
      .pipe(catchError(null));
  }

  // Salvar Tipo
  salvarTipo(model: any) {
    return this.http
      .post(this.baseUrlProcesso + 'tipos/', model, httpOptionsPost)
      .pipe(catchError(null));
  }
  // Tipo de tarefa
  getTipoTarefas(idClassificacao: number): Observable<TiposTarefas[]> {
    return this.http
      .get(this.baseUrlProcesso + 'tipostarefas/' + idClassificacao, httpOptionsPost)
      .pipe(catchError(null));
  }
  getTipoTarefasConfiguracao(idClassificacao: number): Observable<TiposTarefas[]> {
    return this.http
      .get(this.baseUrlProcesso + 'tipostarefas/Conf/' + idClassificacao, httpOptionsPost)
      .pipe(catchError(null));
  }
  getTipoTarefa(id: number): Observable<TiposTarefas> {
    return this.http
      .get(this.baseUrlProcesso + 'tipostarefas/tipotarefa/' + id, httpOptionsPost)
      .pipe(catchError(null));
  }
  gravarTipoTarefa(model: any): Observable<any> {
    return this.http
      .post(this.baseUrlProcesso + 'tipostarefas', model, httpOptionsPost)
      .pipe();
  }
 eliminarTipoTarefa(id: number): Observable<any> {
    return this.http
      .post(this.baseUrlProcesso + 'tipostarefas/eliminar', id, httpOptionsPost)
      .pipe();
  }
 // fim Tipo de tarefa

  // ProcessoArtigo
  getPrArtigosFornecedor(idArtigo?: number): Observable<ArtigosFornecedor[]> {
    return this.http
      .get(this.baseUrlProcesso + 'processosartigos/fornecedor/' + idArtigo, httpOptionsPost)
      .pipe(catchError(null));
  }
  // Fim ProcessoArtigo

    // Processo Tipo Arquivo
  getTiposArquivos(idClassificacao: number): Observable<ProcessosArquivosTipos[]> {
    return this.http
      .get(this.baseUrlProcesso + 'arquivos/tipos/' + idClassificacao, httpOptionsPost)
      .pipe(catchError(null));
  }
  getTodosTiposArquivos(idClassificacao: number): Observable<ProcessosArquivosTipos[]> {
    return this.http
      .get(this.baseUrlProcesso + 'arquivos/todostipos/' + idClassificacao, httpOptionsPost)
      .pipe(catchError(null));
  }
  getTipoArquivo(idTipoArquivo: number): Observable<ProcessosArquivosTipos> {
    return this.http
      .get(this.baseUrlProcesso + 'arquivos/tipo/' + idTipoArquivo, httpOptionsPost)
      .pipe(catchError(null));
  }
  eliminarTipoArquivo(idTipo: number) {
    return this.http
      .post(this.baseUrlProcesso + 'arquivos/eliminar', idTipo, httpOptionsPost)
      .pipe(catchError(null));
  }
  salvarTipoArquivo(model: any) {
    return this.http
      .post(this.baseUrlProcesso + 'arquivos/tipo/', model, httpOptionsPost)
      .pipe(catchError(null));
  }
  // Fim Processo Tipo Arquivo
  gravarPermissoes(modelo: any) {
    return this.http
      .post(this.baseUrlProcesso + 'permissoes', modelo, httpOptionsPost)
      .pipe(catchError(null));
  }

  getContratosXML(dataInicio: any, dataFim: any) {

   return this.http.get(this.baseUrlProcesso + 'relatorios/contratosXml/' + dataInicio, httpOptionsPost);
  }

  getRelatoriosXML(tipo: any, dataInicio: any, dataFim: any) {
    return this.http.get(this.baseUrlProcesso + 'relatorios/' + tipo + 'Xml/' + dataInicio + '/' + dataFim, httpOptionsPost);
  }

  getRelatoriosContractosXML(dataInicio: any, ) {
    return this.http.get(this.baseUrlProcesso + 'relatorios/contratosXml/' + dataInicio , httpOptionsPost);
  }

  getRelatorioGarantiasXML(dataInicio?: any, dataFim?: any) {
    console.log('Datta no service: ', dataInicio);
    return this.http.get(this.baseUrlProcesso + 'relatorios/garantiasXml/' + dataInicio, httpOptionsPost);
  }

  getTiposDadosCaracteristica(): Observable<any> {
    return this.http.get(this.baseUrlProcesso + 'caracteristicas/tipoDados',  httpOptionsPost);
  }
  // Localização
  getTiposLocalizacao(idClassificacao: number): Observable<any> {
    return this.http.get(this.baseUrlProcesso + 'localizacao/tipos/' + idClassificacao,  httpOptionsPost)
    .pipe();
  }
  getLocalizacaoValoresFixo(idTipoLocalizacao: number): Observable<any> {
    return this.http.get(this.baseUrlProcesso + 'localizacao/valorFixo/' + idTipoLocalizacao,  httpOptionsPost)
    .pipe();
  }
  getTiposLocalizacaoConf(idClassificacao: number): Observable<any> {
    return this.http.get(this.baseUrlProcesso + 'localizacao/tiposConf/' + idClassificacao,  httpOptionsPost)
    .pipe();
  }
  getTipoLocalizacao(idTipoLocalizacao: number): Observable<any> {
    return this.http.get(this.baseUrlProcesso + 'localizacao/tipo/' + idTipoLocalizacao,  httpOptionsPost)
    .pipe();
  }
  getLocalizacao(idProcesso: number): Observable<TipoLocalizacao[]> {
    return this.http.get(this.baseUrlProcesso + 'localizacao/' + idProcesso,  httpOptionsPost)
    .pipe(catchError(null));
  }

  gravarTipoLocalizacao(modelo: any): Observable<any> {
    return this.http.post(this.baseUrlProcesso + 'localizacao/tipo/', modelo,  httpOptionsPost)
    .pipe();
  }
  getItensProcessoFA(parametros) {
    return this.http.post(this.baseUrlProcesso + 'processos/ProcessosFactItens', parametros, httpOptionsPost).pipe();
  }

  getClassificacaoProcessosFA() {
        // https://appdemo.kiami.ao/pro/api/v1/classificacoes/facturacao
        return this.http.get(this.baseUrlProcesso + 'classificacoes/facturacao');
  }

  getProcessosFA() {
      return this.http.get(this.baseUrlProcesso + 'processos/facturacao');
  }

  getArtigosProcessosFA(idIdioma?: number, idProcesso?: number) {
      return this.http.get(this.baseUrlProcesso + 'processos/processoF/' + idIdioma + '/' + idProcesso);
  }
}
