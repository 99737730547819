import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, share } from 'rxjs/operators';
import { Utilizadores } from 'app/shared/models/aplicacao/utilizadores';
import { UtilizadoresEstados } from 'app/shared/models/aplicacao/utilizadoresestados';
import { environment } from 'environments/environment';
import { MensagemDto } from 'app/shared/models/aplicacao/mensagemdto';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': 'application/json',
  }),
};
const httpOptionsPost = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root',
})
export class UtilizadoresService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiAplicacao}`;
  }

  getUtilizadores(): Observable<any> {
    return this.http
      .get(this.baseUrl + 'aplicacao/utilizadores', httpOptions)
      .pipe(catchError(null), share());
  }
  getUtilizadoresEstados(): Observable<any> {
    return this.http
      .get(this.baseUrl + 'aplicacao/utilizadores/estados', httpOptions)
      .pipe(catchError(null), share());
  }

  getUtilizador(id: number): Observable<any> {
    return this.http
      .get(this.baseUrl + 'aplicacao/utilizadores/' + id, httpOptions)
      .pipe(catchError(this.handleError));
  }

  removerUtilizador(model: any): Observable<any> {
    return this.http
      .post(
        this.baseUrl + 'aplicacao/utilizadores/remover/',
        model,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  alterarPassword(model: any): Observable<MensagemDto> {
    return this.http
      .post(
        this.baseUrl + 'aplicacao/utilizadores/alterarpassword/',
        model,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  salvarUtilizador(model: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'aplicacao/utilizadores/', model, httpOptionsPost)
      .pipe(catchError(this.handleError));
  }

  editarUtilizador(model: any) {
    return this.http
      .post(
        this.baseUrl + "aplicacao/utilizadores/editar/",
        model,
        httpOptionsPost
      )
      .pipe(catchError(this.handleError));
  }

  editarPerfil(model: any) : Observable<any>{
    return this.http
      .post(this.baseUrl + "aplicacao/perfil/editar/", model, httpOptionsPost)
      .pipe(catchError(this.handleError));
  }

  editarLayoutUtilizador(modelo: any): Observable<any> {
    return this.http
      .post(
        this.baseUrl + "aplicacao/utilizadores/editarLayout",
        modelo,
        httpOptions
      )
      .pipe();
  }
  recuperarSenha(email: string): Observable<any> {
    return this.http
      .post(
        this.baseUrl + "aplicacao/recuperarSenha/" + email,
        null
      )
      .pipe();
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
