import { filter } from 'rxjs/operators';
import { CmsFormularioService } from './../../services/cms/cms-formulario.service';
import { CmsFormulario } from './../../models/cms/cms-formularios';
import { Component, OnInit, Input } from '@angular/core';
import { Menu } from '../../models/aplicacao/menu';
import { Observable } from 'rxjs';
import { ProcessosClassificacoes } from '../../models/processos/processosclassificacoes';
import { Entidades } from '../../models/clientes/entidades';
import { ArtigosClassificacoes } from '../../models/artigos/artigosclassificacoes';
import { menuCode } from '../../fake.db/data';
import { AplicacaoService } from 'app/shared/services/aplicacao-service/aplicacao.service';
import { ClientesGeraisService } from 'app/shared/services/clientes-service/clientes-gerais.service';

import { ArtigosService } from 'app/shared/services/artigos/artigos.service';
import { ProcessosService } from 'app/shared/services/processos/processos.service';
import { MenuDataService } from '../../services/aplicacao-service/data/menu-data.service';
import { UtilizadoresService } from 'app/shared/services/aplicacao-service/utilizadores.service';
import { LoginService } from 'app/shared/services/aplicacao-service/login.service';
import { Utilizadores } from 'app/shared/models/aplicacao/utilizadores';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MenuDinamico } from 'app/shared/models/aplicacao/menudinamico';
import {AppDataService} from '../../services/data/app-data.service';
import { CmsPaginaService } from 'app/shared/services/cms/cms-pagina.service';
import { CmsPaginaListagem } from 'app/shared/models/cms/cms-paginaListagem';
import { CmsGruposregistosService } from 'app/shared/services/cms/cms-gruposregistos.service';
import { RecursosHumanosService } from 'app/shared/services/recursos-humanos/recursos-humanos.service';
import { EventosService } from 'app/shared/services/eventos/eventos.service';
import {Router} from '@angular/router';
import { CmsGeralService } from 'app/shared/services/cms/cms-geral.service';
import { CmsGaleriasService } from 'app/shared/services/cms/cms-galerias.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  menus: Menu[];

  // tslint:disable-next-line: no-input-rename
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;

  public listamenus: Menu[] = [];
  public listamenu: string[] = [];
  public listaMenusObservable$: Observable<string[]>;
  public clientesEntidades: Entidades[];
  public processosClassificacoes: ProcessosClassificacoes[];
  public artigosClassificacao: ArtigosClassificacoes[];
  public idMenuActivo: number;
  listaMenu: any[];
  listaMenuArtigo: any[];
  listaMenuProc: MenuDinamico[];
  listaMenuEvent: MenuDinamico[];
  listaMenuClient: MenuDinamico[];
  listaMenuFornecedor: MenuDinamico[];
  codigoMenu = menuCode;
  utlizador: Utilizadores;
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  codigoMenuFinancas = 'FACTUR';
  codigoMenuArtigos = 'ARTIGO';
  codigoMenuProcessos = 'PROCES';
  codigoMenuCliente = 'CLIENT';
  codigoMenuEvento = 'EVENTO';
  codigoMenuCMS = 'GESCON';
  codigoMenurRecursosHumanos = 'RECHUM';
  usaMenuFacturacao = false;
  usaMenuArtigo = false;
  usaMenuProcesso = false;
  usaMenuCliente = false;
  usaMenuFornecedor = false;
  usaMenuCMS = false;
  usaMenuEvento = false;
  usaMenuRecursosHumanos = false;
  paginas: CmsPaginaListagem[] = [];
  gruposRegistos: any[] = [];
  listaFormularios: CmsFormulario[] = [];
  idIdioma = 1;
  rotaActual: string;
  listaEstuturasOrganizativas: any[] = [];
  listaModulosEventos: any[] = [];

  codIdiomaDefoult: any = "PT";

  ConfiguracaoCMS: any;
  galerias:  any[] = [];
  EmDesenvolvimento=false;

  constructor(
    private aplicacaoService: AplicacaoService,
    private clientesGeraisService: ClientesGeraisService,
    private artigosService: ArtigosService,
    private processosService: ProcessosService,
    private utilizadoresService: UtilizadoresService,
    private loginService: LoginService,
    private menuData: MenuDataService,
    private appDataService: AppDataService,
    private paginaService: CmsPaginaService,
    private grupoService: CmsGruposregistosService,
    private formularioService: CmsFormularioService,
    private geralService: CmsGeralService,
    private eventosService: EventosService,
    private appStore: AppDataService,
    private galeriaService: CmsGaleriasService,
  ) {
      appStore.getRotalActual().subscribe((res: any) => {this.rotaActual = res; });
  }
  ngOnInit() {
    this.aplicacaoService.getAplicacao().subscribe((res) => {
      this.EmDesenvolvimento = res.codTipoInstalacao==='D'?true:false;
    });
    this.actualizarToken();
    this.menuData.getListaMenus().subscribe((menus) => {
      if (menus.length > 0) {
        this.listamenus = menus;
        this.criarListaMenu(menus);
      } else {
        this.aplicacaoService.getMenus().subscribe((res) => {
          this.listamenus = res;
          this.criarListaMenu(res);
          this.menuData.setListaMenus(res);
        });

       
      }
    });
  }

  criarListaMenu(menus: Menu[]) {
    for (let i = 0; i < menus.length; i++) {
      this.listamenu.push(menus[i].codMenu);

      if (menus[i].codMenu === this.codigoMenuCliente) {
        this.listaMenuClient = menus[i].menusDinamicos;
        this.clientesGeraisService.getEntidades().subscribe((res) => {
          this.clientesEntidades = res.objecto;
        });
        this.usaMenuCliente = true;
      }
      if (menus[i].codMenu === 'FORNEC') {
        this.usaMenuFornecedor = true;
        this.listaMenuFornecedor = menus[i].menusDinamicos;
      }

      if (menus[i].codMenu === this.codigoMenuArtigos) {
        this.listaMenuArtigo = menus[i].menusDinamicos;
        this.artigosService.getClassificacoesArtigos().subscribe((res) => {
          this.artigosClassificacao = res;
        });
        this.usaMenuArtigo = true;
      }
      
      if (menus[i].codMenu === this.codigoMenuEvento) {
        this.usaMenuEvento = true;
        this.listaMenuEvent = menus[i].menusDinamicos;
        this.eventosService.getClassificacoes(this.codIdiomaDefoult).subscribe(x => {
          this.listaModulosEventos = x.objecto;
        }, erro => {});
      }

      if (menus[i].codMenu === this.codigoMenuCMS) {
        this.usaMenuCMS = true;
        this.getConfiguracao();
        this.getPaginas(this.idIdioma);
        this.getGalerias(this.idIdioma.toString())
        this.getGrupos(this.idIdioma);
        const activo = true;
        this.getFormularios(activo);
      }
      if (menus[i].codMenu === this.codigoMenuProcessos) {
        this.listaMenuProc = menus[i].menusDinamicos;
        this.processosService.getClassificacoes().subscribe((res) => {
          this.processosClassificacoes = res;
        });
        this.usaMenuProcesso = true;
        this.appDataService.setEstadoModuloProcessos(this.usaMenuProcesso);
      }
      if (menus[i].codMenu === this.codigoMenuFinancas) {
        this.usaMenuFacturacao = true;
      }

      if (menus[i].codMenu === this.codigoMenurRecursosHumanos) {
        this.usaMenuRecursosHumanos = true;
        // this.recursosHumanosService.getListaEstruturasOrgnizativas().subscribe(x => {
        //    this.listaEstuturasOrganizativas = x.objecto.filter(y => y.activo === true); 
        // },
        // erro => {});
      }
    }
  }

  getNomeModulo(idClassificacao?: number): string {
    if (idClassificacao > 0) {
      const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
      if (obj && obj.nomeModuloP?.length>0) { return obj.nomeModuloP; }
      else{
        return 'Módulos';
      }
    }
    return 'Módulos';  
  }
  usarModulo(idClassificacao:number):boolean{
    if (idClassificacao > 0) {
      const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
      if (obj && obj.idOpcModulos>1) { return true }
    }
    return false;
  }

  usaInscricao(idClassificacao:number):boolean{
    if (idClassificacao > 0) {
      const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
      if (obj && obj.idOpcInscricoes > 1){ 
        return true 
      }
      return false;
    }
    return false;
  }

  Formacao(idClassificacao: number):boolean{
    if (idClassificacao > 0) {
      const obj = this.listaModulosEventos?.find(x => x.idClassificacao === idClassificacao);
      if (obj && obj.codNatureza =="F") { 
        return true
       }
       return false;
    }
    return false;
  }

  getMenuNaoDinamico(codMenu: string, i): boolean {
    return !this.listamenus.find((m) => m.codMenu === codMenu)?.dinamico;
  }

  getCliente(idClassificacao: number): Entidades {
    if (this.clientesEntidades) {
      return this.clientesEntidades?.find((m) => m.id === idClassificacao);
    }
    return null;
  }

  getArtigo(idClassificacao: number): ArtigosClassificacoes {
    if (this.artigosClassificacao) {
      return this.artigosClassificacao.find((m) => m.idClassificacao === idClassificacao);
    }
    return null;
  }

  getProcessos(idClassificacao: number): ProcessosClassificacoes {
    if (this.processosClassificacoes) {
      return this.processosClassificacoes.find(
        (m) => m.idClassificacao === idClassificacao
      );
    }
    return null;
  }

  getMenu(codMenu: string): Menu {
    return this.listamenus.find((m) => m.codMenu === codMenu);
  }

  actualizarToken() {
    const token = localStorage.getItem('token');
    this.decodedToken = this.jwtHelper.decodeToken(token);
    this.loginService.decodedToken = this.decodedToken;
    this.getUtilizador(this.loginService.decodedToken.nameid);
  }

  getUtilizador(idUser: number) {
    this.utilizadoresService.getUtilizador(idUser).subscribe((user) => {
      if (user.sysAdmin) {
        const admin = new Menu('ADMINI', 'Administração', 15, false, null);
        this.listamenus.push(admin);
        this.listamenu.push('ADMINI');
      }
    });
  }

  getGalerias(codIdioma: string) {
    this.galeriaService.galerias(codIdioma).subscribe(
      (res) => {
        this.galerias = res.objecto.filter(g=>g.activo==true);
      },
      (error) => {
        console.log(error.error.mensagem);
      }
    );
  }

  getPaginas(idIdioma: number) {
    this.paginaService.paginas(idIdioma).subscribe((res) => {
      this.paginas = res.objecto;
    }, error => {
      console.log(error.error.mensagem);
    });
  }

  getGrupos(idIdioma: number) {
    this.grupoService.gruposRegistos(idIdioma).subscribe((res) => {
      this.gruposRegistos = res.objecto;
    }, error => {
      console.log(error.error.mensagem);
    });
  }

  getConfiguracao(): void {
      this.geralService.getConfiguracao().subscribe((res: any) => {
          this.ConfiguracaoCMS = res.objecto;
        })
  }
  getFormularios(activo) {
    this.formularioService.formularios(activo).subscribe((res) => {
      this.listaFormularios = res.objecto;
    }, error => {
      console.log('Lista de formulários Erro: ', error.error.mensagem);
    });
  }

  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        { name: 'SUBITEM', state: 'cards' },
        { name: 'SUBITEM', state: 'buttons' },
      ],
    });
  }
  activarDropDown(id:number) {
    if (this.idMenuActivo === id) {
      return (this.idMenuActivo = null);
    }
    return (this.idMenuActivo = id);
  }
}
