import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CmsGruposregistosService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.cmsUrl}`+'gruposRegistos/';
  }
  gruposRegistos(idIdioma:number): Observable<any> {
    return this.http.get(this.baseUrl + "listagem/"+idIdioma).pipe();
  }

  gruposRegisto(idGrupo: number): Observable<any> {
    return this.http.get(this.baseUrl + idGrupo).pipe();
  }
  removerPaginas(idGrupo: number): Observable<any> {
    return this.http.post(this.baseUrl + "eliminar/" + idGrupo,null).pipe();
  }

  gravarGrupo(modelo: any): Observable<any> {
    return this.http.post(this.baseUrl, modelo).pipe();
  }
}
