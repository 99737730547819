import {Component, OnDestroy, OnInit} from '@angular/core';
import {Aplicacao} from '../../models/aplicacao/aplicacao';
import {AplicacaoService} from '../../services/aplicacao-service/aplicacao.service';
import {AppStoreService} from '../../services/aplicacao-service/data/app-store.service';
import {Subscription} from 'rxjs';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  aplicacao: Aplicacao;
  licencaEmpresa = '...';
  subscricao: Subscription[] = [];
  constructor(private aplicacaoService: AplicacaoService, private appStore: AppStoreService) {}


  ngOnInit() {
    if (!this.aplicacao){
      this.getAplicacao();
    }
  }
  getAplicacao() {
    this.appStore.getAplicacao().subscribe((app: any) => {
      if (app) {
        this.licencaEmpresa = app?.licencaEmpresa;
      } else {
        // Adilson Cambamba 28/03/2022
        this.subscricao.push(
            this.aplicacaoService.getAplicacao().subscribe(res => {
              this.aplicacao = res;
              this.appStore.setAplicacao(res);
              this.licencaEmpresa = res?.licencaEmpresa;
            }, error => {
              console.log('Falha ao obter dados da aplicação');
            })
        );
      }
    });
  }

  ngOnDestroy() {
    if (this.subscricao.length > 1) {
      this.subscricao.forEach((sub: Subscription) => {
        sub.unsubscribe();
      });
    }
  }
}
