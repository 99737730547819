<footer class="main-footer">
  <div class="container-dynamic d-flex">
    <p class="mb-0">Licenciado a: <span class="text-secondary">{{licencaEmpresa}}</span>
     <ng-container *ngIf="aplicacao && aplicacao.codTipoInstalacao && aplicacao?.codTipoInstalacao.toUpperCase() !== 'P'">
       <span class="px-1">-</span>Tipo de instalação: <span class="text-secondary">{{aplicacao?.tipoInstalacao}}</span>
     </ng-container>
    </p>
    <span class="m-auto"></span>
    <p class="mb-0">
      Desenvolvido por &ensp;<a href="https://kiamisoft.ao" class="text-secondary"> KiamiSoft</a>
    </p>
  </div>
</footer>
