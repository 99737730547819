import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from 'environments/environment';
import { ArtigosClassificacoes } from 'app/shared/models/artigos/artigosclassificacoes';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ArtigosEstados } from 'app/shared/models/artigos/artigosestados';
import { ArtigosTipos } from 'app/shared/models/artigos/artigostipos';
import { ArtigosVarianteOpcoes } from 'app/shared/models/artigos/artigovarianteopcoes';
import { ArtigosVariante } from 'app/shared/models/artigos/artigosvariantes';
import { OpccoesValidade } from 'app/shared/models/artigos/opccoesvalidade';
import { CategoriasTextos } from 'app/shared/models/artigos/categoriastextos';
import { Artigos } from 'app/shared/models/artigos/artigos';
import { ArtigosFacturacao } from 'app/shared/models/artigos/artigosfacturacao';
import { ArtigosConfiguracaoVariante } from 'app/shared/models/artigos/artigoconfiguracaovariante';
import { ArtigosTiposVariante } from 'app/shared/models/artigos/artigotiposvariantes';
import { ArtigosValores } from 'app/shared/models/artigos/artigosvalores';
import { ArtigoCodTipoArtigo } from 'app/shared/models/artigos/artigocodtipoartigo';
import { FornecedoresArtigos } from 'app/shared/models/fornecedores/fornecedoresartigos';
import { ArtigosNaturezas } from 'app/shared/models/artigos/artigosnaturezas';
import { VarianteConunas } from 'app/shared/models/artigos/varianteartigocolunas';
import { Categorias } from 'app/shared/models/processos/categorias';
import { ArtigosLista } from 'app/shared/models/artigos/artigoslista';
import { Fornecedores } from 'app/shared/models/fornecedores/fornecedores';
import { ArParametrizacao } from 'app/shared/models/artigos/arparametrizacao';
import { TipoArmazem } from 'app/shared/models/artigos/tipoarmazem';
import { Armazem } from 'app/shared/models/artigos/armazem';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer ' + localStorage.getItem('token'),
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ArtigosService {

  baseUrl: string;
   artigosFA$ = new BehaviorSubject<any>(null);

   constructor(private http: HttpClient) {
   this.baseUrl = `${environment.apiArtigos}`;
  }

  getClassificacoesArtigos(): Observable<ArtigosClassificacoes[]> {
    return this.http
      .get(this.baseUrl + 'classificacoes', httpOptions)
      .pipe(catchError(null));
  }

  removerClassificacao(idclassificacao: any) {
    console.log(idclassificacao)
    return this.http
      .post(this.baseUrl + 'classificacoes/parametrizacao/' + idclassificacao, httpOptions)
      .pipe();
  }
  getParametrizacao(): Observable<ArParametrizacao[]> {
    return this.http
      .get(this.baseUrl + 'classificacoes/parametrizacoes', httpOptions)
      .pipe(catchError(null));
  }
  gravarParametrizacao(modelo: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'classificacoes/parametrizacao', modelo, httpOptions)
      .pipe();
  }

  getClassificacaoArtigo(id: number): Observable<ArtigosClassificacoes> {
    return this.http
      .get(this.baseUrl + 'classificacoes/' + id, httpOptions)
      .pipe(catchError(null));
  }

  getVariantes(idClassificacao: number): Observable<ArtigosVariante[]> {
    return this.http
      .get(this.baseUrl + 'variantes/' + idClassificacao, httpOptions)
      .pipe(catchError(null));
  }
  getVariantesArtigo(idClassificacao: number): Observable<ArtigosVariante[]> {
    return this.http
      .get(this.baseUrl + 'variantes/artigo/' + idClassificacao, httpOptions)
      .pipe(catchError(null));
  }
  getVarianteColunas(idVariante: number): Observable<VarianteConunas> {
    return this.http
      .get(this.baseUrl + 'variantes/variante/colunas/' + idVariante, httpOptions)
      .pipe(catchError(null));
  }
  getValores(
    idClassificacao: number,
    codIdioma: number
  ): Observable<ArtigosValores[]> {
    return this.http
      .get(
        this.baseUrl + 'variantes/valores/' + idClassificacao + '/' + codIdioma,
        httpOptions
      )
      .pipe(catchError(null));
  }
  getVarianteOpcoes(
    idClassificacao: number
  ): Observable<ArtigosVarianteOpcoes[]> {
    return this.http
      .get(this.baseUrl + 'varianteOpcoes/' + idClassificacao, httpOptions)
      .pipe(catchError(null));
  }
  getVarianteTipos(
    idClassificacao: number
  ): Observable<ArtigosTiposVariante[]> {
    return this.http
      .get(this.baseUrl + 'variantes/varianteTipo/' + idClassificacao, httpOptions)
      .pipe(catchError(null));
  }

  getCategorias(
    idClassificacao: number,
    codIdioma: number
  ): Observable<any> {
    return this.http
      .get(
        this.baseUrl + 'categorias/' + idClassificacao + '/' + codIdioma,
        httpOptions
      )
      .pipe(catchError(null));
  }

  getCategoria(idCategoria: number): Observable<any> {
    return this.http
      .get(this.baseUrl + 'categorias/' + idCategoria, httpOptions)
      .pipe(catchError(null));
  }
 eliminarCategoria(idCategoria: number): Observable<any> {
    return this.http
      .post(this.baseUrl + 'categorias/eliminar', idCategoria, httpOptions)
      .pipe();
  }
  getCategoriasTexto(
    idClassificacao: number,
    idCategoria: number,
    codIdioma: string,
  ): Observable<any> {
    return this.http
      .get(
        this.baseUrl +
        'categorias/' +
        idClassificacao +
        '/' +
        idCategoria +
        '/' +
        codIdioma,
        httpOptions
      )
      .pipe(catchError(null));
  }


  getArtigo(idArtigo: number, codIdioma: string): Observable<any> {
    return this.http
      .get(this.baseUrl + 'artigo/' + codIdioma + '/' + idArtigo, httpOptions)
      .pipe();
  }
  getArtigos(model: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'getArtigo', model, httpOptions)
      .pipe(catchError(null));
  }
  getArtigoIva(CodPais: string): Observable<any> {
    return this.http
      .get(this.baseUrl + 'getIvaArtigo/'+ CodPais, httpOptions)
      .pipe(catchError(null));
  }

  getTodosArtigos(codIdioma: string): Observable<Artigos[]> {
    return this.http
      .get(this.baseUrl + 'todosartigos/' + codIdioma, httpOptions)
      .pipe(catchError(null));
  }
  salvarCategoria(model: any) {
    return this.http
      .post(this.baseUrl + 'categorias/', model, httpOptions)
      .pipe(catchError(null));
  }
  // Tipo de Artigo
  getTipos(
    idClassificacao: number,
    codIdioma: string
  ): Observable<any> {
    return this.http
      .get(
        this.baseUrl + 'tipo/tipos/' + idClassificacao + '/' + codIdioma,
        httpOptions
      )
      .pipe();
  }
  getTipo(idTipo: number, codIdioma: string): Observable<any> {
    return this.http
      .get(this.baseUrl + 'tipo/' + idTipo + '/' + codIdioma, httpOptions)
      .pipe(catchError(null));
  }
  getTipotexto(idTipo: number, codIdioma: string): Observable<any> {
    return this.http
      .get(this.baseUrl + 'tipo/texto/' + idTipo + '/' + codIdioma, httpOptions)
      .pipe(catchError(null));
  }
  salvarTipo(model: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'tipo/', model, httpOptions)
      .pipe();
  }
  editarTipo(idTipo: number, model: ArtigosTipos) {
    return this.http
      .put(this.baseUrl + 'tipo/' + idTipo, model, httpOptions)
      .pipe(catchError(null));
  }
  RemoverTipoArtigo(idTipo: number) {
    return this.http
      .post(this.baseUrl + 'tipo/eliminar/', idTipo, httpOptions)
      .pipe(catchError(null));
  }
  removerTipo(idTipo: number) {
    return this.http
      .post(this.baseUrl + 'tipo/' + idTipo, httpOptions)
      .pipe(catchError(null));
  }
  // Fim Tipo de Artigo
  //  gravarArtigo(modelo: any): Observable<any>  {
  //       return this.http
  //           .post(this.baseUrl + 'artigo', modelo, httpOptions)
  //           .pipe();
  //   }

  salvaArtigo(model: any): Observable<any>  {
    return this.http
      .post(this.baseUrl+"artigo", model, httpOptions)
      .pipe();
  }
  removerArtigo(idArtigo: number): Observable<any> {
    return this.http
      .post(this.baseUrl + 'removerArtigo', idArtigo, httpOptions)
      .pipe();
  }
  getArtigosFacturacao(codIdioma: number): Observable<ArtigosFacturacao[]> {
    return this.http
      .get(this.baseUrl + 'facturacao/' + codIdioma, httpOptions)
      .pipe(catchError(null));
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
  salvarOpcaoVariante(model: any) {
    return this.http
      .post(this.baseUrl + 'variantes/varianteOpcoes/', model, httpOptions)
      .pipe(catchError(null));
  }
  salvarVariante(model: any) {
    return this.http
      .post(this.baseUrl + 'variantes/', model, httpOptions)
      .pipe(catchError(null));
  }
  getVarianteOpcao(idVarianteOpcao: number): Observable<ArtigosVarianteOpcoes> {
    return this.http
      .get(this.baseUrl + 'variantes/varianteOpcao/' + idVarianteOpcao, httpOptions)
      .pipe(catchError(null));
  }
  getVariante(idVariante: number): Observable<ArtigosVariante> {
    return this.http
      .get(this.baseUrl + 'variantes/variante/' + idVariante, httpOptions)
      .pipe(catchError(null));
  }
  getVarianteConfiguracoes(
    idVariante: number
  ): Observable<ArtigosConfiguracaoVariante[]> {
    return this.http
      .get(this.baseUrl + 'variantes/varianteConfiguracao/' + idVariante, httpOptions)
      .pipe(catchError(null));
  }
  // Fornecedores
  getFornecedores(codIdioma: string): Observable<Fornecedores[]> {
    return this.http
      .get(this.baseUrl + 'fornecedores/' + codIdioma, httpOptions)
      .pipe(catchError(null));
  }

  gravarArtigosFornecedor(model: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'fornecedores/', model, httpOptions)
      .pipe();
  }

  getArtigosFornecedor(
    idFornecedor: number,
    codIdioma: string
  ): Observable<FornecedoresArtigos[]> {
    return this.http
      .get(
        this.baseUrl + 'fornecedores/' + idFornecedor + '/' + codIdioma,
        httpOptions
      )
      .pipe(catchError(null));
  }

  removerArtigosFornecedor(modelo: any) {
    return this.http.post(
      this.baseUrl + 'fornecedores/remover/',
      modelo,
      httpOptions
    );
  }
  // Fim Fornecedores
  getArtigoVariante(idArtigo: number): Observable<any> {
    return this.http
      .get(this.baseUrl + 'variantes/GetArtigoVariante/' + idArtigo, httpOptions)
      .pipe(catchError(null));
  }

  gerarToken(model: any) {
    return this.http
      .post(this.baseUrl + 'gerartoken/', model)
      .pipe(catchError(null));
  }

  getTiposArtigo(codNatureza: string): Observable<any> {
    if (!codNatureza) { codNatureza = '*'; }
    return this.http
      .get(this.baseUrl + 'tiposArtigo/' + codNatureza, httpOptions)
      .pipe();
  }
  getTodosTiposArtigo(): Observable<any> {
    return this.http
      .get(this.baseUrl + 'todosTiposArtigo', httpOptions)
      .pipe(catchError(null));
  }
  gravarTiposArtigo(modelo: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'tiposArtigo', modelo, httpOptions)
      .pipe();
  }
  eliminarTiposArtigo(modelo: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'tipoArtigo/eliminar', modelo, httpOptions)
      .pipe();
  }
  getTipoArtigo(id: number): Observable<ArtigosNaturezas> {
    return this.http
      .get(this.baseUrl + 'tipoArtigo/' + id, httpOptions)
      .pipe(catchError(null));
  }
  getCodigoArtigo(idClassificacao: number, data: string, IdTipoArtigo: number) {
    return this.http.get(this.baseUrl + 'codigo/' + idClassificacao + '/' + data + '/' + IdTipoArtigo, httpOptions).pipe(catchError(null));
  }
//#region Armazem
  getTiposArmazem(): Observable<TipoArmazem[]> {
    return this.http
      .get(this.baseUrl + 'armazem/armazem/tipos-armazens', httpOptions)
      .pipe(catchError(null));
  }
  getTiposArmazemConf(): Observable<TipoArmazem[]> {
    return this.http
      .get(this.baseUrl + 'armazem/tipo-armazem-conf/listar', httpOptions)
      .pipe(catchError(null));
  }
  getTipoArmazem(id: number): Observable<TipoArmazem> {
    return this.http
      .get(this.baseUrl + 'armazem/armazem/tipoArmazem/' + id, httpOptions)
      .pipe(catchError(null));
  }
  gravarTipoArmazem(modelo: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'armazem/tipoArmazem', modelo, httpOptions)
      .pipe();
  }
  eliminarTipoArmazem(idTipoArmazem: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'armazem/tipoArmazem/eliminar', idTipoArmazem, httpOptions)
      .pipe();
  }

  getArmazens(modelo: any): Observable<Armazem[]> {
    return this.http
      .post(this.baseUrl + 'armazem/armazens', modelo, httpOptions)
      .pipe(catchError(null));
  }
  getArmazensLista(): Observable<any> {
    return this.http
      .get(this.baseUrl + 'armazem/armazem/armazens', httpOptions)
      .pipe();
  }
  getArmazem(idArmazem: number): Observable<Armazem> {
    return this.http
      .get(this.baseUrl + 'armazem/armazem/' + idArmazem, httpOptions)
      .pipe(catchError(null));
  }
  gravarArmazem(modelo: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'armazem/armazem', modelo, httpOptions)
      .pipe();
  }
  eliminarArmazem(idArmazem: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'armazem/armazem/eliminar', idArmazem, httpOptions)
      .pipe();
  }
  //#endregion
  getNatureza(): Observable<any> {
    return this.http
      .get(this.baseUrl + 'artigo/naturezas', httpOptions)
      .pipe(catchError(null));
  }

   //#region stock
   getStocks(modelo: any): Observable<Armazem[]> {
    return this.http
      .post(this.baseUrl + 'stock/stocks', modelo, httpOptions)
      .pipe(catchError(null));
  }
  getStockMovimento(): Observable<any> {
    return this.http
      .get(this.baseUrl + 'stock/stockmovimento', httpOptions)
      .pipe();
  }
  getStocksMovimentos(filtro: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'stock/stocksmovimentos', filtro, httpOptions)
      .pipe();
  }

  getArtigosStock(): Observable<any> {
    return this.http
      .get(this.baseUrl + 'stock/stock/artigos', httpOptions)
      .pipe();
  }
  getStock(idStock: number): Observable<Armazem> {
    return this.http
      .get(this.baseUrl + 'stock/stock/' + idStock, httpOptions)
      .pipe(catchError(null));
  }
  gravarStock(modelo: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'stock/stock', modelo, httpOptions)
      .pipe();
  }
  eliminarStock(idStock: any): Observable<any> {
    return this.http
      .post(this.baseUrl + 'stock/stock/eliminar', idStock, httpOptions)
      .pipe();
  }

  getTextoArtigo(idArtigo: number, codIdioma: string): Observable<any> {
    return this.http
      .get(this.baseUrl + 'artigo/texto/' + idArtigo + '/' + codIdioma, httpOptions)
      .pipe(catchError(null));
  }
  BuscarUnidadeMedida(): Observable<any> {
    return this.http
      .get(this.baseUrl + 'unidadeMedida', httpOptions)
      .pipe();
  }

  BuscarArtigoVarianteComRegistoValores(idArtigo: number,idVariante:number): Observable<any> {
    return this.http
      .get(this.baseUrl + 'variantes2/varianteCamposOrganizado/' + idArtigo+'/'+idVariante, httpOptions)
      .pipe(catchError(null));
  }

  //#endregiongetStock

    setArtigosFAStore(artigos: any): any {
        this.artigosFA$.next(artigos);
    }
    getArtigosFAStore(): any {
        return this.artigosFA$.asObservable();
    }
}
