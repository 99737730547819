import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CmsFormularioService {
  baseUrl: string;
  apiUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.cmsUrl}`+'formularios/';
    this.apiUrl = `${environment.cmsUrl}`;
  }
  formularios(activo:boolean): Observable<any> {
    return this.http.get(this.baseUrl +activo).pipe();
  }
  formularioPorIdioma(idFormulario:number,idIdioma:number): Observable<any> {
    return this.http.get(this.baseUrl +idFormulario+'/'+idIdioma).pipe();
  }
  formulario(idFormulario:number): Observable<any> {
    return this.http.get(this.baseUrl +'formulario/'+idFormulario).pipe();
  }

  getVariaveis(): Observable<any> {
    return this.http.get(this.baseUrl +'variaveisEmailsFormularios').pipe();
  }
  getRegistoFormulario(idRegisto: number, codIdioma: string): any {
    return this.http.get(this.apiUrl + 'formulariosRegistos/' + idRegisto + '/' + codIdioma);
  }

  listaFormularios(activo: boolean, codIdioma:number): Observable<any> {
    return this.http.get(this.baseUrl + 'lista/'+activo+'/'+codIdioma).pipe();
  }
  removerFormulario(idFormulario: number): Observable<any> {
    return this.http.post(this.baseUrl + "eliminar/" + idFormulario,null).pipe();
  }

  gravarFormulario(modelo: any): Observable<any> {
    return this.http.post(this.baseUrl, modelo).pipe();
  }
}