import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appDecimalPlaces]'
})
export class DecimalPlacesDirective {
  private regex: RegExp = new RegExp(/^\d*\,?\d{0,2}$/g);
  private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    console.log('Pressed Key',  event.key);

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    // const next: string = [current.slice(0, position), event.key === 'Decimal' ? ',' : event.key, current.slice(position)].join('');
    const next: string = [current.slice(0, position), event.key === ',' ? ',' : event.key, current.slice(position)].join('');
    console.log('Valor: ', next);
   // if (parseFloat(next) >= 100) { event.preventDefault();}
    console.log('Pressed', current);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
