import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {AccaoEntidade} from '../../models/clientes/accao-entidade';
import {Entidades} from '../../models/clientes/entidades';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'accoes-multiplas-tabela',
    template: `
        <mat-card-title class="mb-0 border-b">
            <div class="max-height-45 flex-x-btw flex-y-center choice-sec" fxLayout="row wrap" fxLayout.xs="column">
                <div class="p-0 flex-y-center">
                        <button *ngIf="podeImprimir" (click)="imprimir()"  mat-icon-button matTooltip="Imprimir" matTooltipClass="yellow-900">
                            <mat-icon>local_printshop</mat-icon>
                        </button>
                        <button  *ngIf="false" mat-icon-button matTooltip="Mudar visualização da tabela" matTooltipClass="red-A700">
                            <mat-icon>view_list</mat-icon>
                        </button>
                        <button *ngIf="podeExportarExcel" (click)="exportarExcel()" mat-icon-button matTooltip="Exportar para Excel" matTooltipClass="green-900" class="img-button">
                            <img src="assets/images/icons/excel-icon-alt.svg" style="border-radius: 0 !important; width: 20px !important; height: 20px !important;" alt="" />
                        </button>
                        <button *ngIf="podeExportarPDF" (click)="exportarPDF()" mat-icon-button matTooltip="Exportar para PDF" matTooltipClass="red-900" class="img-button">
                            <img src="assets/images/icons/pdf-icone-outline.svg" style="border-radius: 0 !important; width: 20px !important; height: 20px !important;" alt="" />
                        </button>
                        <button *ngIf="podeEnviarEmail" type="button" (click)="enviarEmail()" mat-icon-button matTooltip="Enviar e-mail" matTooltipClass="teal-800">
                             <mat-icon class="text-primary">email</mat-icon>
                        </button>
                        <button *ngIf="podePagarDoc" (click)="pagarDocumento()" mat-icon-button
                                color="primary"
                                matTooltip="Pagar documentos"
                                matTooltipClass="teal-800"
                        >
                            <mat-icon>credit_card</mat-icon>
                        </button>
                    <button *ngIf="podeEliminar" mat-icon-button matTooltip="Remover" matTooltipClass="teal-800" color="warn" (click)="remover()">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </div>
                <div  class="pt-0 pr-05 py-6 flex flex-y-center">
                    <button  [ngClass]="{ desabilitar: podeCriarItem === false }" class="btn-filter" mat-raised-button color="primary" routerLink="{{rotaItem}}" >
                        Criar {{tituloBtn}}
                    </button>
                </div>
            </div>
        </mat-card-title>
    `
// tslint:disable-next-line:component-class-suffix
}) export class TabAccoesMultiplas implements OnChanges {
    @Output() aoEnviarEmail: EventEmitter<any> = new EventEmitter();
    @Output() aoImprimir: EventEmitter<any> = new EventEmitter();
    @Output() aoDeletar: EventEmitter<any> = new EventEmitter();
    @Output() aoRemover: EventEmitter<any> = new EventEmitter();
    @Output() aoExportarExcel: EventEmitter<any> = new EventEmitter();
    @Output() aoExportarPDF: EventEmitter<any> = new EventEmitter();
    @Output() aoNavegarRota: EventEmitter<any> = new EventEmitter();
    @Output() aoAvaliar: EventEmitter<any> = new EventEmitter();
    @Output() aoPagarDocumento: EventEmitter<any> = new EventEmitter();

    @Input() podeEliminar: boolean;
    @Input() podeImprimir: boolean;
    @Input() podeExportarExcel: boolean;
    @Input() podeExportarPDF: boolean;
    @Input() podeEnviarEmail: boolean;
    @Input() podePagarDoc: boolean;
    @Input() podeCriarItem: boolean;
    @Input() accaoEntidade: AccaoEntidade;
    @Input() listaAccoes: AccaoEntidade[] = [];
    @Input() podeInserirCLI;
    @Input() mostrarAvaliacao: boolean;
    @Input() entidade: Entidades;
    @Input() tituloBtn: string;
    @Input() rotaItem: string;

    enviarEmail() {this.aoEnviarEmail.emit(true); }
    pagarDocumento() {this.aoPagarDocumento.emit(true); }
    imprimir() { this.aoImprimir.emit(true); }
    remover() { this.aoRemover.emit(true); }
    deletar() { this.aoDeletar.emit(true); }
    exportarExcel() {  this.aoExportarExcel.emit(true);  }
    exportarPDF() {  this.aoExportarPDF.emit(true);  }
    navegar() { this.aoNavegarRota.emit(true); }

    ngOnChanges(changes: SimpleChanges) { }

}
