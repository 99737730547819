import {NgModule, ElementRef, HostListener} from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontSizeDirective } from './font-size.directive';
import { ScrollToDirective } from './scroll-to.directive';
import { AppDropdownDirective } from './dropdown.directive';
import { DropdownAnchorDirective } from './dropdown-anchor.directive';
import { DropdownLinkDirective } from './dropdown-link.directive';
import { EgretSideNavToggleDirective } from './egret-side-nav-toggle.directive';
import { EgretSidenavHelperDirective, EgretSidenavTogglerDirective } from './egret-sidenav-helper/egret-sidenav-helper.directive';
import { EgretHighlightDirective } from './egret-highlight.directive';
import {SelectedActiveDirective} from './selected-active.directive';
import {TwoDecimalsDirective} from './two-decimals.directive';
import {DecimalPlacesDirective} from './decimal-places.directive';
import {InputDecimalsDirective} from './input-decimals.directive';
import {FormatDecimalsDirective} from './format-decimals.directive';
import {FixTopDirective} from './fix-top.directive';
import {BlockSpaceDirective} from './block-space.directive';
import {MaxDigitosDirective} from './max-digitos.directive';
import {FundoCardDirective} from './fundo-card.directive';


const directives = [
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  EgretSideNavToggleDirective,
  EgretSidenavHelperDirective,
  EgretSidenavTogglerDirective,
  EgretHighlightDirective,
  SelectedActiveDirective,
  TwoDecimalsDirective,
  InputDecimalsDirective,
  DecimalPlacesDirective,
  FormatDecimalsDirective,
  FixTopDirective,
  BlockSpaceDirective,
  MaxDigitosDirective,
  FundoCardDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
    declarations: directives,
    exports: directives
})
export class SharedDirectivesModule {}

