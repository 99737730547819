import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CmsPaginaService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.cmsUrl}`+'paginas/';
  }
  tiposPaginas(): Observable<any> {
    return this.http.get(this.baseUrl + "tiposPaginas").pipe();
  }
  paginas(idIdioma: number): Observable<any> {
    return this.http.get(this.baseUrl + idIdioma).pipe();
  }
  paginasListagem(): Observable<any> {
    return this.http.get(this.baseUrl).pipe();
  }
  removerPaginas(idPagina: number): Observable<any> {
    return this.http.delete(this.baseUrl + "eliminar/" + idPagina).pipe();
  }

  pagina(idPagina: number): Observable<any> {
    return this.http.get(this.baseUrl + "pagina/" + idPagina).pipe();
  }
  paginaTexto(idPagina: number, codIdioma: string): Observable<any> {
    return this.http.get(this.baseUrl + "pagina/texto/" + idPagina+'/'+codIdioma).pipe();
  }
  gravarPagina(modelo: any): Observable<any> {
    return this.http.post(this.baseUrl, modelo).pipe();
  }
}
