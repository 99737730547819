import { MenuDinamico } from '../aplicacao/menudinamico';
export class Menu {
  constructor(
    public codMenu?: string,
    public menu?: string,
    public ordem?: number,
    public dinamico?: boolean,
    public menusDinamicos?: MenuDinamico[]
  ) {}
}
