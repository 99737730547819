import {Component, Inject, OnInit} from '@angular/core';
import {ExportFile} from '../../../services/export-file/export-file';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ClientesService} from '../../../services/clientes-service/clientes.service';
import {FiltrosClientes} from '../../../models/clientes/filtros/filtroclientes';
import {Clientes} from '../../../models/clientes/clientes';
import {EmpresaService} from '../../../services/aplicacao-service/empresa.service';
import {Empresa} from '../../../models/empresa/empresa';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-export-excel',
  templateUrl: './export-excel.component.html',
  styleUrls: ['./export-excel.component.scss']
})
export class ExportExcelComponent implements OnInit {
  clientesFiltros: FiltrosClientes;
  processoArquivos = [
    {
      Id: 1,
      Tipo: 'Tipo 1',
      Nome: 'Nome do arquivo 1',
      Tamanho: 23456789
    },
    {
      Id: 1,
      Tipo: 'Tipo 1',
      Nome: 'Nome do arquivo 1',
      Tamanho: 23456789
    },
    {
      Id: 1,
      Tipo: 'Tipo 1',
      Nome: 'Nome do arquivo 1',
      Tamanho: 23456789
    },
    {
      Id: 1,
      Tipo: 'Tipo 1',
      Nome: 'Nome do arquivo 1',
      Tamanho: 23456789
    }
  ];
 clientes: Clientes[];
 filtro: FiltrosClientes = {
   Codigo: '',
   CodigoFiltro: 1,
   Email: '',
   IdEntidade: 1,
   IdEstados: [],
   CodIdiomas: [],
   CodPaises: [],
   IdTipos: [],
   NIF: '',
   Nome: ''
 };
empresa: Empresa;
  empresaSub: Subscription;

  constructor( private file: ExportFile,
               private clientesService: ClientesService,
               private modalRef: MatDialogRef<ExportExcelComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any,
               private empresaService: EmpresaService
  ) { }

  ngOnInit(): void {
    this.clientesService.getClientesListagem(this.filtro).subscribe(res => {
      this.clientes = res.objecto;
    });


   this.empresaSub = this.empresaService.getEmpresas().subscribe(res => {
      this.empresa = res[0];
    })
  }

  exportarExcel() {
    this.file.exportToFile(this.data.ficheiro, 'Clientes', 'TabArquivos', 'landscape');
    this.modalRef.close();
  }

    cancelar() {
        this.modalRef.close();
    }
}
