import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Empresa } from "app/shared/models/empresa/empresa";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { EstadosEmpresa } from "app/shared/models/empresa/estadosempresa";
import { environment } from "environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: "Bearer " + localStorage.getItem("token"),
  }),
};

@Injectable({
  providedIn: "root",
})
export class EmpresaService {
  baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.apiAplicacao}`;
  }

  getEmpresa(id: number): Observable<Empresa> {
    return this.http
      .get(this.baseUrl + 'aplicacao/empresas/' + id, httpOptions)
      .pipe(catchError(null));
  }
  criarNovaEmpresa(): Observable<any> {
    return this.http
      .get(this.baseUrl + 'aplicacao/empresas/criarNovaEmpresa', httpOptions)
      .pipe();
  }
  getEmpresas(): Observable<Empresa[]> {
    return this.http
      .get(this.baseUrl + 'aplicacao/empresas', httpOptions)
      .pipe(catchError(null));
  }

  getEmpresasActivas(): Observable<Empresa[]> {
    return this.http
      .get(this.baseUrl + "aplicacao/empresas/activas", httpOptions)
      .pipe(catchError(null));
  }

  getEstadosEmpresa(): Observable<EstadosEmpresa[]> {
    return this.http
      .get(this.baseUrl + "aplicacao/empresas/estados", httpOptions)
      .pipe(catchError(null));
  }

  BuscarCodRegimeIva(): Observable<any> {
    return this.http
      .get(this.baseUrl + "aplicacao/empresas/regimeIva", httpOptions)
      .pipe(catchError(null));
  }
  BuscarCodRegimeIvaEmpresas(): Observable<any> {
    return this.http
      .get(this.baseUrl + "aplicacao/empresas/regimeIvaEmpresas", httpOptions)
      .pipe(catchError(null));
  }
  salvar(model: any):Observable<any> {
    return this.http
      .post(this.baseUrl + "aplicacao/empresas/", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  editar(model: any):Observable<any> {
    return this.http
      .post(this.baseUrl + "aplicacao/empresas/editar", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  remover(model: any):Observable<any> {
    return this.http
      .post(this.baseUrl + "aplicacao/empresas/remover", model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
