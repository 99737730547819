import { Injectable } from "@angular/core";
import { Menu } from "app/shared/models/aplicacao/menu";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MenuDataService {
  private listaMenus$ = new BehaviorSubject<Menu[]>([]);

  constructor() {}

  setListaMenus(menus: Menu[]) {
    this.listaMenus$.next(menus);
  }

  getListaMenus(): Observable<Menu[]> {
    return this.listaMenus$.asObservable();
  }
}
