<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <span fxFlex></span>
  <!-- Language Switcher -->
  <button mat-button [matMenuTriggerFor]="menu">
  </button>
<mat-menu #menu="matMenu">
</mat-menu>

  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Top left user menu -->

  <div
          [matMenuTriggerFor]="accountMenu"
          class="user-profile-settings"
          style="display: flex"
  >
    <button  id="profile-avatar"  mat-icon-button  class="topbar-button-right img-button pr-0 mr-0" style="margin-right: 0 !important; overflow: hidden"
    >
      <img
              [src]="this.fotoPerfil | safe: 'resourceUrl'"
              alt=""
              style="width: 100% !important; height: auto !important"
      />
    </button>
    <button mat-button [disableRipple]="true" class="topbar-button-right pl-0">
      <span>{{loginService.decodedToken?.unique_name}}</span>
    </button>
  </div>


  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="['/perfil/',loginService.decodedToken?.nameid]">
      <mat-icon>account_box</mat-icon>
      <span>Perfil</span>
    </button>

    <button mat-menu-item (click)="isCustomizerOpen = true" >
      <mat-icon>color_lens</mat-icon>
      <span>Aparência</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sair</span>
    </button>
  </mat-menu>
</mat-toolbar>


<app-customizer [isCustomizerOpen]="isCustomizerOpen" (fechar)="fecharCustomizer($event)"></app-customizer>
