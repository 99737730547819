import {Entidades} from '../clientes/entidades';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import { Paises } from '../aplicacao/paises';

export class Util {
  static getLabelProvincia(codPais: string, paises?: Paises[]): string {
    return paises?.find(p => p.codPais === codPais)?.idOpcCodigoPostal < 2 ? 'Distrito' : 'Província';
  }
  static getLabelMunicipio(codPais: string, paises?: Paises[]): string {
    return paises?.find(p => p.codPais === codPais)?.idOpcCodigoPostal < 2 ? 'Concelho' : 'Munícipio';
  }
  public OpcaoNaoUsado = 1;

  public static strToDate(valor: string) {
    if (valor) {
      const dataString = valor
          .split('-')
          .reverse()
          .toString()
          .replace(',', '-')
          .replace(',', '-');
      const dataConvertida =  new Date(dataString).getTime();
      return dataConvertida;
    }

    return '';
  }

  public static strParaDataISO(dataStr: any) {
      if (dataStr) {
          return Util.FromStrToDate(new Intl.DateTimeFormat('pt-PT').format(Date.parse(dataStr)));
      } else {
          return '';
      }
  }

  public static FromStrToDate(valor: any) {
    if (valor) {
      const data = valor.split('/').reverse().toString().replace(',', '-').replace(',', '-');
      return data;
    }
    return '';
  }

    public static replaceSliceInStrToDate(valor: any) {
        if (valor) {
            return valor.split('/').toString().replace(',', '-').replace(',', '-');
        }
        return '';
    }

  public static getData(dia: number, mes: number, ano: any): string {
    const mesActual = mes + 1;
    const data = ano.toString() + '-' +
      (mesActual < 10 ? '0' + mesActual.toString() : mesActual.toString()) +
      '-' + (dia < 10 ? '0' + dia.toString() : dia.toString());
    return data;
  }

  public static momToDate(momentDate: any): string {
   if(momentDate) {
       if (momentDate._isAMomentObject) {
           const mesActual = momentDate._i.month + 1;
           const data =
               momentDate._i.year.toString() +
               '-' +
               (mesActual < 10 ? '0' + mesActual.toString() : mesActual.toString()) +
               '-' +
               (momentDate._i.date < 10 ? '0' + momentDate._i.date.toString() : momentDate._i.date.toString());
           return data;
       } else  {
           const rgx = new RegExp('/', 'gi');
           const date = Intl.DateTimeFormat('en-US').format(new Date(momentDate));
           return date.replace(rgx, '-');
       }
   } else {
       return;
   }

  }

  public static getAnoData(dia: number, mes: number, ano: any): string {
    return ano;
  }

  public static getLabelNome(entidade: Entidades) {
    return entidade.idOpcPriUltimoNome === 1 ? 'Nome' : 'Nome completo';
  }

  public static alterarFormatoData(data: string) {
    if (data) {
      return (
        data.substring(6, 11) +
        '-' +
        data.substring(3, 5) +
        '-' +
        data.substring(0, 2)
      );
    }
    return '';
  }

 public static getFormErros(form: AbstractControl) {
    if (form instanceof FormControl) {
      console.log('Form Control: ', form.errors);
      return form.errors ?? null;
    }
    if (form instanceof FormArray) {
      console.log('Form Control: ', form.errors);
      return form.errors ?? null;
    }
    if (form instanceof FormGroup) {
      const groupErrors = form.errors;
      const formErrors = groupErrors ? {groupErrors} : {};
      Object.keys(form.controls).forEach(key => {
        const error = this.getFormErros(form.get(key));
        if (error !== null) {
          formErrors[key] = error;
          console.log('Erros form group ', error);        }
      });
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }

    public static formatarDecimal(strNum: any):string {
      const valor = strNum.toString();
        if (valor) {
            if (valor.includes('.')) {
                const valorComVirgula = valor.toString().replace('.', ',');
                const partesDoNumero = valorComVirgula.split(',');
                let numeroFormatado = '';
                if (partesDoNumero[1].length === 1) {
                    numeroFormatado = partesDoNumero[0] + ',' + partesDoNumero[1] + '0';
                } else {
                    numeroFormatado = partesDoNumero[0] + ',' + partesDoNumero[1];
                }
                return numeroFormatado;
            } else {
                return valor + ',00';
            }
        } else {
            return '0,00';
        }
    }

    public static reverterDecimal(valor: any) {
        if (valor && typeof(valor) === 'string' && valor.includes(',')) {
            const valorComPonto = valor.toString().replace(',', '.');
                return parseFloat(valorComPonto);
        } else {
            return valor;
        }
    }

  public static convertToFloat(strNum: any) {
    if (strNum &&  typeof(strNum) === 'string') {
      const val = strNum.replace(',', '.');
      return parseFloat(val);
    }else{
      return strNum;
    }
  }

    public static converterParaFloat(strNum: any) {
        if (strNum && typeof(strNum) === 'string' && strNum.includes(',')) {
            const val = strNum.replace(',', '.');
            return parseFloat(val);
        } else {
            return strNum;
        }
    }

  public static fromStrToDate(date: any) {
    const dtTime = new Intl.DateTimeFormat('pt-PT', {
        year: 'numeric',  month: 'numeric', day: 'numeric'
    }).format(Date.parse(date));
    return dtTime;
  }

  public static StrToIntlDate(date: any) {
    const dtTime = new Intl.DateTimeFormat('pt-PT', {
        year: 'numeric',  month: 'numeric',  day: 'numeric'
    }).format(Date.parse(date));
    return dtTime;
  }

  public static DownloadXML(dados) {
    const element = document.createElement('a');
    const blob = new Blob([dados.objecto], {
      type: 'text/xml',
    });
    element.href = URL.createObjectURL(blob);
    element.setAttribute('download', dados.mensagem);
    document.body.appendChild(element);
    element.click();
  }

  public static downloadAnexo(anexo: string, nomeAnexo: string){
     if (anexo) {
         const link = document.createElement('a');
         link.href = anexo;
         link.download = nomeAnexo;
         link.click();
     } else {
         console.log('Sem anexo para descarregar');
     }
  }
  public static CurrencyEnToPt(valor) {
    const delimiter = ',';
    let val = valor.split('.', 2);
    const d = val[1];
    let i = parseInt(val[0], 10);
    if (isNaN(i)) { return ''; }
    let minus = '';
    if (i < 0) { minus = '-'; }
    i = Math.abs(i);
    let n = new String(i);
    const valTemp = [];
    while (n.length > 3) {
      const nn = n.substr(n.length - 3);
      valTemp.unshift(nn);
      n = n.substr(0, n.length - 3);
    }
    if (n.length > 0) { val.unshift(n); }
    n = valTemp.join(delimiter);
    if (d.length < 1) { valor = n; } else { valor = n + '.' + d; }
    valor = minus + valor;
    return valor;
  }

  public static validarCodigoLivre(classificacao, form: any, campo: any) {
    if (!classificacao) {
      const valorCodigo =  form.get(campo).value;
      if (valorCodigo.trim().length < 1) {
       form.get(campo).setValue('');
       form.get(campo).setValidators(Validators.required);
       form.get(campo).updateValueAndValidity();
       form.get(campo).markAsTouched({onlySelf: true});
       return false;
      }
    }
  }

    strParaDouble(valor: any) { if (valor) { return valor.replaceAll(/\./gi, '').replace(',', '.'); } else { return 0; } }

// Função para formatação de casas decimais //


 public static  formatarCasasDecimais(valor) {
        // Valor string //
        if (valor && typeof(valor) === 'string') {
            if (valor.includes(',')) {
                const decimalPart = valor.split(',', 2)[1];
                const integerPart = valor.split(',', 2)[0];
                if (decimalPart.length > 1) {
                    return valor;
                } else {
                    return (integerPart + ',' + decimalPart + '0');
                }
            } else {
                return valor + ',00';
            }
        }

        // Valor numérico //
        if (valor && typeof(valor) === 'number') {
            const num = '' + valor;
            if (num.includes('.')) {
                const decimalPart = num.split('.', 2)[1];
                const integerPart = num.split('.', 2)[0];
                if (decimalPart.length > 1) {
                    return valor;
                } else {
                    return (integerPart + ',' + decimalPart + '0');
                }
            } else {
                return valor + ',00';
            }
        }
    }

   formatarStrParaFloat(valor) {
        if (valor && typeof(valor) === 'string') {
            if (valor.includes(',')) {
                const num = valor.replace(',', '.');
                return parseFloat(parseFloat(num).toFixed(2));
            } else {
                return parseFloat(parseFloat(valor).toFixed(2));
            }
        } else {
            return valor;
        }
    }// Fim da função da converterDecimal



}
